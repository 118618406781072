import { getProductReviews, getAvailableCountries } from "@shopware-pwa/api-client";
import type { KippieLocation, ListingResult } from "@shopware-pwa/types";
import type { Product } from "@shopware-pwa/types";
import type { ShopwarePickupTimeSlot } from "~/types/kippie";

export const useShopwareProductReviews = async (product: Product) => {
	const apiInstance = useShopwareApi();
	const productReviewResult = await getProductReviews(
		product.id,
		{
			limit: 5,
			associations: {
				customer: {}
			},
			sort: [
				{
					field: "createdAt",
					order: "desc",
					naturalSorting: false
				}
			]
		},
		apiInstance
	);

	return productReviewResult;
};

type StatsResponse = {
	totalCount: number;
	fiveStarCount: number;
	fourStarCount: number;
	threeStarCount: number;
	twoStarCount: number;
	oneStarCount: number;
};

export const useShopwareProductReviewStats = async (product: Product) => {
	const apiInstance = useShopwareApi();
	const getProductReviewStats = await apiInstance.invoke.get<StatsResponse>(
		`/store-api/product/${product.id}/reviews/stats`
	);

	return getProductReviewStats.data;
};

export const useShopwareCountries = async () => {
	const apiInstance = useShopwareApi();

	return useLazyAsyncData(
		"shopware:countries",
		async () => {
			const { elements } = await getAvailableCountries(apiInstance);

			return elements;
		},
		{ immediate: true }
	);
};

export const useShopwarePickupSlots = (pickupLocation: Ref<KippieLocation | undefined>) => {
	const apiInstance = useShopwareApi();

	const pickupLocationId = computed(() => pickupLocation?.value?.id);

	const pickupTimeslotsResponse = useAsyncData(
		`shopware:pickupDays:${pickupLocationId.value}`,
		async () => {
			if (!pickupLocation?.value) return [];

			const { data } = await apiInstance.invoke.post<ShopwarePickupTimeSlot[]>(
				`/store-api/pickup-location/${pickupLocation.value.id}/timeslots`
			);

			return data;
		},
		{
			watch: [pickupLocationId]
		}
	);
	return pickupTimeslotsResponse;
};

export const useShopwarePickupLocations = (
	geoLocation?: Ref<{ lat: number; lng: number }>,
	onlyPickupLocations: boolean = false
) => {
	const apiInstance = useShopwareApi();

	const response = useData(async () => {
		const filters = [];

		if (onlyPickupLocations) {
			filters.push({
				type: "equals",
				field: "isPickupLocation",
				value: true
			});
		}

		const { data } = await apiInstance.invoke.post<ListingResult<KippieLocation>>(
			`/store-api/pickup-location`,
			{
				filter: filters,
				limit: geoLocation?.value ? 10 : 100
			},
			{
				params: geoLocation?.value && {
					latitude: geoLocation.value.lat,
					longitude: geoLocation.value.lng
				}
			}
		);

		// FIX: Shopware API returns all locations, when coordinates are given because of native mysql query criteria not working
		if (onlyPickupLocations) {
			return data.elements.filter((item) => item.isPickupLocation === true);
		}

		return data.elements;
	}, []);

	if (geoLocation) {
		watch(geoLocation, () => {
			response.execute();
		});
	}

	return response;
};

export const useShopwarePickupLocation = (id: string) => {
	const apiInstance = useShopwareApi();

	return useAsyncData(`shopware:stores:${id}`, async () => {
		const { data } = await apiInstance.invoke.post<KippieLocation>(`/store-api/pickup-location/${id}`);
		return data;
	});
};

export const useShopwarePickupLocationBySlug = (slug: string) => {
	const apiInstance = useShopwareApi();
	return useAsyncData(`shopware:stores:${slug}`, async () => {
		const { data } = await apiInstance.invoke.post(`/store-api/pickup-location`, {
			limit: 1,
			associations: {
				businessHours: {},
				specialBusinessHours: {}
			},
			filter: [
				{
					field: "slug",
					type: "equals",
					value: slug
				}
			]
		});
		return data?.elements[0] || null;
	});
};
